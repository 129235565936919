<template>
  <div>
    <b-card
      id="customer-form-detail-base"
      ref="customer-form-detail-base"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
        <b-badge
          class="font-small-4 px-1 py-50"
          :variant="value.is_close_deal ? 'light-danger' : 'light-success'"
        >
          <feather-icon
            size="20"
            :icon="value.is_close_deal ? 'CheckSquareIcon' : 'BookOpenIcon'"
          />
          {{ value.is_close_deal ? 'Đã close deal' : 'Giao dịch đang mở' }}
        </b-badge>
      </div>
      <div class="card-body">

        <b-row>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Tên công ty
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.company_name }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Người liên lạc
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.host_name }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Điện thoại
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.host_phone1 }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Email
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.email }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="d-flex align-items-start gap-1">
              <label>
                Tình trạng
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ (STATUS_TYPE_CUSTOMER.find(item => item.value === value.status) || {}).text || '' }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="d-flex align-items-start gap-1">
              <label>
                Ngành nghề
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.career }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="d-flex align-items-start gap-1">
              <label>
                Số lần đã gặp
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.meeting_count }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="d-flex align-items-start gap-1">
              <label>
                Số lần đã chốt
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.confirm_deal_count }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="d-flex align-items-start gap-1">
              <label>
                Nguồn
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ (CUSTOMER_SOURCE.find(item => item.value === value.source) || {}).text || '' }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="d-flex align-items-start gap-1">
              <label>
                Hẹn khách
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.meeting_date ? formatDate(value.meeting_date) : '' }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="d-flex align-items-start gap-1">
              <label>
                Ghi chú
              </label>
              <div class="text-primary font-weight-bolder mb-1">
                {{ value.note }}
              </div>
            </div>
          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <div>
              <label>
                Thông tin về diện tích
              </label>
              <pre class="p-1">{{ value.area_description }}</pre>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
          >
            <div>
              <label>
                Thông tin về khu vực
              </label>
              <pre class="p-1">{{ value.region_description }}</pre>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
          >
            <div>
              <label>
                Thông tin về ngân sách
              </label>
              <pre class="p-1">{{ value.budget_description }}</pre>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
          >
            <div>
              <label>
                Thông tin khác
              </label>
              <pre class="p-1">{{ value.more_description }}</pre>
            </div>
          </b-col>
        </b-row>

        <small class="font-weight-bolder text-primary">Thông tin khác</small>
        <hr class="mt-0">

        <b-row>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Số lượng ô-tô xe máy
              </label>
              <div class="font-weight-bolder mb-1">
                {{ value.vehicle_description }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Thời gian cần chuyển vào
              </label>
              <div class="font-weight-bolder mb-1">
                {{ formatDate(value.move_in_at) }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Mục đích công năng sử dụng
              </label>
              <div class="font-weight-bolder mb-1">
                {{ value.purpose_description }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Tòa nhà đã xem
              </label>
              <div class="font-weight-bolder mb-1">
                {{ value.viewed_office }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Ngoài giờ
              </label>
              <div class="font-weight-bolder mb-1">
                {{ value.over_time_description }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Thời hạn hợp đồng
              </label>
              <div class="font-weight-bolder mb-1">
                {{ value.contract_time }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Tòa nhà đang thuê
              </label>
              <div class="font-weight-bolder mb-1">
                {{ value.current_office }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <div>
              <label>
                Địa chỉ website và ngành nghề
              </label>
              <div class="font-weight-bolder mb-1">
                {{ value.more_info }}
              </div>
            </div>
          </b-col>
        </b-row>

        <small class="font-weight-bolder text-primary">Tiêu chí lọc, phân loại khách hàng (HCM)</small>
        <hr class="mt-0">

        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <div>
              <label>
                Quận/Huyện
              </label>
              <div class="mb-1 text-primary">
                <span
                  v-for="(d, dIdx) in value.districts"
                  :key="'customer-detail-district-list-' + d.id"
                  class="detail-text"
                ><template v-if="dIdx!==0">,&nbsp;</template>{{ d.name }}
                </span>
              </div>
            </div>

            <div>
              <label>
                Phường/Xã
              </label>
              <div class="mb-1 text-primary">
                <template
                  v-if="value.wards && value.wards.length"
                >
                  <span
                    v-for="(w, wIdx) in value.wards"
                    :key="'customer-detail-district-list-' + w.id"
                    class="detail-text"
                  ><template v-if="wIdx!==0">,&nbsp;</template>{{ w.name }}
                  </span>
                </template>
                <template v-else-if="value.ward">
                  {{ value.ward.pre + value.ward.name }}
                </template>
              </div>
            </div>

            <div>
              <label>
                Đường
              </label>
              <div class="mb-1 text-primary">
                <span
                  v-for="d in value.streets"
                  :key="'customer-detail-street-list-' + d.id"
                  class="detail-text"
                >
                  {{ d.name }}
                </span>
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
          >
            <div>
              <label>
                Khoảng giá (USD/m<sup>2</sup>)
              </label>
              <div class="mb-1 text-primary">
                <strong>{{ formatNumber(value.min_price || 0) }}</strong>-<strong>{{ formatNumber(value.max_price || 0) }}</strong>&nbsp;
                <small>USD</small>
              </div>
            </div>

            <div>
              <label>
                Diện tích (m2)
              </label>
              <div class="mb-1 text-primary">
                <strong>{{ formatNumber(value.acreage) }}</strong>
                <feather-icon icon="ArrowRightIcon" />
                <strong>{{ formatNumber(value.max_area) }}</strong>&nbsp;
                <small>m<sup>2</sup></small>
              </div>
            </div>

            <div>
              <label>
                Thời gian nhận khách
              </label>
              <div class="mb-1 text-primary">
                {{ formatDate(value.reception_at) }}
              </div>
            </div>
          </b-col>
        </b-row>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {
      },
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  methods: {},
  setup() {
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';

</style>

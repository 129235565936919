import useCustomer from '@/views/customer/useCustomer'
import CustomerModal from '@/modal/customerModal'
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import useProduct from '@/views/product/useProduct'
import util from '@/global/util'

export default function useCustomerOfficeDetail() {
  const {
    actions,
    customerMenuType,
    customerMenuTypeVI,
    //
    fetchCustomer,
    quotation,
    closeDealCustomer,
    openDealCustomer,
    fetchSuggestedListProduct,
    fetchHistoryUpdateCustomer,
  } = useCustomer()

  const { renderProductOfficeStructure, renderProductAddress } = useProduct()

  const customer = CustomerModal()
  const progressExportPdf = ref(0)
  const isDownloadedPdf = ref(true)
  const suggestedList = ref([])
  const suggestSelectedObject = ref({})
  const suggestSelectedList = ref([])
  const exportPdfLang = ref('vi')
  const routeParams = ref({})
  const totalSuggestedList = ref(0)
  const fitFilter = reactive({
    is_not_exported: ref(null),
    user_id: ref(null),
    price_from: ref(null),
    price_to: ref(null),
    q: ref(null),
  })
  const countBase64ImgLoaded = ref(0)
  const countBase64ImgLoading = ref(0)
  const refreshKey = ref(0)
  const base64ImgObj = ref({})
  const historyUpdate = ref([])
  const tableColumnsHistoryUpdate = [
    {
      label: 'Ngày Nhập',
      key: 'created_at',
      formatter: (value, key, item) => util.formatDate(value),
    },
    {
      label: 'Tên',
      key: 'creator.fullname',
    },
    {
      label: 'Số ĐT',
      key: 'data.host_phone1',
    },
    {
      label: 'Nguồn',
      key: 'data.host_name',
    },
    {
      label: 'Nhu Cầu',
      key: 'data.title',
      tdClass: 'py-1 minw-250',
    },
    {
      label: 'Ngành Nghề',
      key: 'data.career',
    },
    {
      label: 'Khoảng Giá',
      key: 'data.price',
    },
    {
      label: 'Gặp + Thay Đỗi Nhu Cầu',
      key: 'data.meeting_count',
    },
    {
      label: 'Tình Trạng',
      key: 'data.status',
    },
    {
      label: 'Ghi Chú',
      key: 'data.note',
      tdClass: 'minw-250',
    },
    {
      label: 'Tương Tác Gần nhất',
      key: 'updated_at',
      formatter: (value, key, item) => util.formatDate(value),
    },
    {
      label: 'Đã Chốt',
      key: 'data.confirm_deal_count',
    },
  ]
  const tableColumnsSuggestedProduct = [
    {
      label: 'Chọn',
      key: 'checked',
    },
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'text-primary position-relative',
    },
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Tên',
      key: 'company_name',
      tdClass: 'position-relative minw-150',
    },
    {
      label: 'Địa chỉ',
      key: 'dia-chi',
      thClass: 'minw-200',
      formatter: (value, key, item) => renderProductAddress(item),
    },
    {
      label: 'Mô tả diện tích',
      key: 'rental_area_description',
      thClass: 'minw-150',
    },
    {
      label: 'Kết cấu',
      key: 'ket-cau',
      thClass: 'minw-150',
      formatter: (value, key, item) => renderProductOfficeStructure(item),
    },
    {
      label: 'Giá trọn gói',
      key: 'price',
    },
    {
      label: 'Người đăng',
      key: 'nguoi-dang',
    },
  ]
  const perPage = ref(10)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const users = computed(() => store.state.app.users)

  // show old product with image _160 resolution
  const showImage160 = ref([])

  const fetchSuggestedList = (id, cb) => fetchSuggestedListProduct({
    id: id || routeParams.value.id,
    q: http.buildQuery({
      page: currentPage.value - 1,
      perpage: perPage.value,
      ...fitFilter,
    }),
  }, res => {
    suggestedList.value = res.data.result || []
    totalSuggestedList.value = res.data.total || 0

    // for debug
    // suggestSelectedList.value = suggestedList.value
    // end for debug

    return cb && cb(res)
  })

  const fetchHistoryUpdate = (data, cb) => fetchHistoryUpdateCustomer(data, res => {
    historyUpdate.value = res && res.data
    historyUpdate.value.reverse()
    return cb && cb(res)
  })

  watch(fitFilter, () => {
    fetchSuggestedList()
  })

  watch(suggestSelectedObject, () => {
    Object.keys(suggestSelectedObject.value).forEach(id => {
      if (!suggestSelectedObject.value[id]) {
        suggestSelectedList.value = suggestSelectedList.value.filter(item => item.id.toString() !== id.toString()) || []
      } else {
        const found = suggestSelectedList.value.find(item => item.id.toString() === id.toString())
        if (!found) {
          suggestSelectedList.value.push(suggestedList.value.find(item => item.id.toString() === id.toString()))
        }
      }
    })
  }, { deep: true })

  watch([currentPage, perPage], () => {
    fetchSuggestedList()
  })

  return {
    customer,
    customerMenuType,
    customerMenuTypeVI,
    actions,
    suggestedList,
    exportPdfLang,
    suggestSelectedObject,
    suggestSelectedList,
    tableColumnsHistoryUpdate,
    tableColumnsSuggestedProduct,
    totalSuggestedList,
    showImage160,
    perPage,
    perPageOptions,
    currentPage,
    routeParams,
    fitFilter,
    users,
    isDownloadedPdf,
    progressExportPdf,
    countBase64ImgLoaded,
    countBase64ImgLoading,
    base64ImgObj,
    refreshKey,
    historyUpdate,
    //
    quotation,
    fetchCustomer,
    closeDealCustomer,
    fetchSuggestedList,
    openDealCustomer,
    fetchHistoryUpdate,
  }
}

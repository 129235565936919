<template>
  <b-card
    no-body
    class="box-shadow-1"
    :class="'bg-light-' + (value.is_close_deal ? 'danger' : 'success')"
  >
    <b-card-body>
      <b-form @submit.prevent>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                Mã
              </div>
              <div class="row-value">
                <span :class="'text-'+ (value.is_close_deal ? 'danger' : 'success')">
                  {{ value.id }}
                </span>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                Người tạo
              </div>
              <div class="row-value">
                <span :class="'text-'+ (value.is_close_deal ? 'danger' : 'success')">
                  {{ value.user && value.user.fullname }}
                </span>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                Ngày tạo
              </div>
              <div class="row-value">
                <span :class="'text-'+ (value.is_close_deal ? 'danger' : 'success')">
                  {{ formatDate(value.created_at) }}
                </span>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                Ngày cập nhật
              </div>
              <div class="row-value">
                <span :class="'text-'+ (value.is_close_deal ? 'danger' : 'success')">
                  {{ formatDate(value.updated_at) }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCol, BForm, BRow, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
}
</script>
<style lang="scss">
.row-label {
  min-width: 80px;
  margin-right: .25rem;
}
.row-value {
  font-weight: bold;
}
</style>

<template>
  <div>
    <b-card
      id="customer-form-detail-content"
      ref="customer-form-detail-content"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CHI TIẾT
        </b-badge>
      </div>
      <div class="card-body">
        <h5 class="font-weight-bold">
          {{ value.title }}
        </h5>
        <br>
        <div v-html="valueContent" />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BCard, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  computed: {
    valueContent() {
      const content = this.value.content || ''
      return content.replaceAll('<p>', '').replaceAll('</p>', '<br/>')
    },
  },
  methods: {
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
</style>
